$colors: (
    "ash": (
        100: theme('colors.ash.100'),
        200: theme('colors.ash.200'),
        300: theme('colors.ash.300'),
        400: theme('colors.ash.400'),
        500: theme('colors.ash.500'),
        600: theme('colors.ash.600'),
        700: theme('colors.ash.700'),
        800: theme('colors.ash.800'),
        900: theme('colors.ash.900'),
        'opposite': white
    ),
    "gray": (
        100: theme('colors.gray.100'),
        200: theme('colors.gray.200'),
        300: theme('colors.gray.300'),
        400: theme('colors.gray.400'),
        500: theme('colors.gray.500'),
        600: theme('colors.gray.600'),
        700: theme('colors.gray.700'),
        800: theme('colors.gray.800'),
        900: theme('colors.gray.900'),
        'opposite': white
    ),
    "red": (
        100: theme('colors.red.100'),
        200: theme('colors.red.200'),
        300: theme('colors.red.300'),
        400: theme('colors.red.400'),
        500: theme('colors.red.500'),
        600: theme('colors.red.600'),
        700: theme('colors.red.700'),
        800: theme('colors.red.800'),
        900: theme('colors.red.900'),
        'opposite': white
    ),
    "orange": (
        100: theme('colors.orange.100'),
        200: theme('colors.orange.200'),
        300: theme('colors.orange.300'),
        400: theme('colors.orange.400'),
        500: theme('colors.orange.500'),
        600: theme('colors.orange.600'),
        700: theme('colors.orange.700'),
        800: theme('colors.orange.800'),
        900: theme('colors.orange.900'),
        'opposite': white
    ),
    "yellow": (
        100: theme('colors.yellow.100'),
        200: theme('colors.yellow.200'),
        300: theme('colors.yellow.300'),
        400: theme('colors.yellow.400'),
        500: theme('colors.yellow.500'),
        600: theme('colors.yellow.600'),
        700: theme('colors.yellow.700'),
        800: theme('colors.yellow.800'),
        900: theme('colors.yellow.900'),
        'opposite': white
    ),
    "green": (
        100: theme('colors.green.100'),
        200: theme('colors.green.200'),
        300: theme('colors.green.300'),
        400: theme('colors.green.400'),
        500: theme('colors.green.500'),
        600: theme('colors.green.600'),
        700: theme('colors.green.700'),
        800: theme('colors.green.800'),
        900: theme('colors.green.900'),
        'opposite': white
    ),
    "teal": (
        100: theme('colors.teal.100'),
        200: theme('colors.teal.200'),
        300: theme('colors.teal.300'),
        400: theme('colors.teal.400'),
        500: theme('colors.teal.500'),
        600: theme('colors.teal.600'),
        700: theme('colors.teal.700'),
        800: theme('colors.teal.800'),
        900: theme('colors.teal.900'),
        'opposite': white
    ),
    "blue": (
        100: theme('colors.blue.100'),
        200: theme('colors.blue.200'),
        300: theme('colors.blue.300'),
        400: theme('colors.blue.400'),
        500: theme('colors.blue.500'),
        600: theme('colors.blue.600'),
        700: theme('colors.blue.700'),
        800: theme('colors.blue.800'),
        900: theme('colors.blue.900'),
        'opposite': white
    ),
    "indigo": (
        100: theme('colors.indigo.100'),
        200: theme('colors.indigo.200'),
        300: theme('colors.indigo.300'),
        400: theme('colors.indigo.400'),
        500: theme('colors.indigo.500'),
        600: theme('colors.indigo.600'),
        700: theme('colors.indigo.700'),
        800: theme('colors.indigo.800'),
        900: theme('colors.indigo.900'),
        'opposite': white
    ),
    "purple": (
        100: theme('colors.purple.100'),
        200: theme('colors.purple.200'),
        300: theme('colors.purple.300'),
        400: theme('colors.purple.400'),
        500: theme('colors.purple.500'),
        600: theme('colors.purple.600'),
        700: theme('colors.purple.700'),
        800: theme('colors.purple.800'),
        900: theme('colors.purple.900'),
        'opposite': white
    ),
    "pink": (
        100: theme('colors.pink.100'),
        200: theme('colors.pink.200'),
        300: theme('colors.pink.300'),
        400: theme('colors.pink.400'),
        500: theme('colors.pink.500'),
        600: theme('colors.pink.600'),
        700: theme('colors.pink.700'),
        800: theme('colors.pink.800'),
        900: theme('colors.pink.900'),
        'opposite': white
    ),
);

$sidebar-width: 280px;