@import "_variables.scss";


@keyframes dropUp {
	from {
		opacity: 0;
		transform: translateY(30px); }
	to {
		opacity: 1;
		transform: translateY(0); } }

@keyframes dropDown {
	from {
		opacity: 0;
		transform: translateY(-30px); }
	to {
		opacity: 1;
		transform: translateY(0); } }

@keyframes spin {
	from {
		transform: rotate(0deg); }
	to {
		transform: rotate(359deg); } }

@keyframes zoom-animation {
	0% {
		transform: scale(1); }
	50% {
		transform: scale(.3); }
	100% {
		transform: scale(1); } }

@keyframes drop {
	0% {
		animation-timing-function: cubic-bezier(0.7815,0.1815,0.7831,0.7206);
		transform: translate(0%, calc(-24.875% / 2));
		opacity: 0; }

	31% {
		animation-timing-function: cubic-bezier(0.1707,0.3173,0.3345,0.9041);
		transform: translate(0%, calc(-1.325% / 2));
		opacity: 1; }

	51% {
		animation-timing-function: cubic-bezier(0.6191,0.1797,0.7336,0.6732);
		transform: translate(0%, calc(-10% / 2));
		opacity: 1; }

	72% {
		animation-timing-function: cubic-bezier(0.2345,0.4707,0.453,1);
		transform: translate(0%, calc(-0.525% / 2));
		opacity: 1; }

	87% {
		animation-timing-function: cubic-bezier(0.53,0.1845,0.7541,0.5953);
		transform: translate(0%, calc(-3.95% / 2));
		opacity: 1; }

	100% {
		transform: translate(0%, 0%);
		opacity: 1; } }

.fade-enter-active, .fade-leave-active {
	transition: opacity 0.3s; }

.fade-enter, .fade-leave-active {
	opacity: 0; }


// #page.wallet-page
//app-content
//padding-bottom: 0 !important
//display: none



 :root {
	font-size: 15px;
 }	// font-weight: 300

html {
	@apply h-full {} }

body {
	@apply h-full {}
	@apply overflow-x-hidden {} }

button {
	font-family: inherit; }

#app {
	@apply h-full {}
	overflow-x: hidden; }


.form-group {
	@apply mb-12 {}
	@apply relative {}
	@apply w-full {}

	&:after {
		//@apply absolute
		@apply block {}
		//@apply duration-300
		@apply w-0 {}
		bottom: -1px;
		//border-bottom: 2px solid theme('colors.blue.500')
		content: '';
		left: 50%;
		transform: translateX(-50%); }

	.input {
		@apply bg-transparent {}
		@apply block {}
		@apply duration-300 {}
		//@apply pb-2
		@apply py-2 {}
		@apply px-3 {}
		@apply text-sm {}
		@apply rounded {}
		@apply w-full {}
		-webkit-appearance: none;
		//border-bottom: 1px solid #E0E5FC
		border: 1px solid #E0E5FC;
		outline: none;
		&:-webkit-autofill,
		&:-webkit-autofill:hover {}
		&:-webkit-autofill:focus {
			@apply bg-transparent {} }
		&:-internal-autofill-selected {
			@apply bg-transparent {}
			@apply text-black {}
			box-shadow: inset 100px 100px 0px #fff; }
		&-file {
			@apply hidden {} }
		&-loading {
			@apply absolute {}
			@apply duration-300 {}
			@apply flex {}
			@apply flex-row {}
			@apply items-center {}
			@apply left-10 {}
			@apply opacity-0 {}
			@apply right-10 {}
			top: calc(theme('spacing.1'));
			.dot {
				@apply bg-blue-500 {}
				@apply h-10px {}
				@apply mr-2 {}
				@apply rounded-lg {}
				@apply w-10px {}
				animation: zoom-animation 1s infinite;
				&:nth-child(2) {
					animation-delay: .15s; }
				&:nth-child(3) {
					animation-delay: .3s; } } } }

	.icon {
		@apply absolute {}
		@apply text-xl {}
		&:not(.caret) {
			@apply w-8 {} }
		top: 0;
		&-left {
			@apply left-0 {} }
		&-right {
			@apply right-0 {} } }

	.label {
		//@apply absolute
		//@apply duration-300
		//@apply opacity-25
		//@apply pointer-events-none
		@apply text-sm {}
		//left: theme('spacing.10')
		//top: calc(theme('spacing.1') - 3px)
 }		//z-index: 1

	&-text {
		// @apply absolute
		@apply mb-10 {}
		@apply -mt-10 {}
		@apply text-sm {}
		// @apply right-0
		// bottom: 0
		// left: theme('spacing.10')
 }		// top: 100%

	&.focused {
		&:after {
			@apply w-full {} }
		.icon-left {
			@apply text-blue-500 {} } }

	&.focused,
	&.active {
		.label {
			@apply opacity-100 {}
			@apply text-blue-500 {}
			@apply text-xs {}
			top: calc(theme('spacing.6') * -1); }
		.input-loading {
			@apply opacity-100 {}
			transition-delay: .2s; } }
	&.no-left-icon {
		.input {
			@apply pl-0 {} }
		.label {
			@apply left-0 {} } }
	&:not(.focused):not(.active) {
		.input {
			@apply text-white {} } }

	// &.textarea
	// 	.text
 }	// 		bottom:

.text-overflow-ellipse {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden; }

.select {
	@apply cursor-pointer {}
	// -webkit-appearance: none
	// background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNyA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC4xMzE1NyAwLjE0MDM3NkMwLjA4OTg2MDQgMC4xODI1MTkgMC4wNTY3NzE3IDAuMjMyNTY0IDAuMDM0MTk1OSAwLjI4NzY1QzAuMDExNjIwMSAwLjM0MjczNiAtMS41NzI4M2UtMDcgMC40MDE3ODMgLTEuNTQ2NzZlLTA3IDAuNDYxNDE1Qy0xLjUyMDdlLTA3IDAuNTIxMDQ2IDAuMDExNjIwMSAwLjU4MDA5MyAwLjAzNDE5NTkgMC42MzUxNzlDMC4wNTY3NzE3IDAuNjkwMjY1IDAuMDg5ODYwNSAwLjc0MDMxIDAuMTMxNTcgMC43ODI0NTNMMy4xNzg3MyAzLjg2NjkyQzMuMjIwNCAzLjkwOTExIDMuMjY5ODcgMy45NDI1OCAzLjMyNDMzIDMuOTY1NDFDMy4zNzg3OSAzLjk4ODI1IDMuNDM3MTcgNCAzLjQ5NjEyIDRDMy41NTUwOCA0IDMuNjEzNDUgMy45ODgyNSAzLjY2NzkxIDMuOTY1NDFDMy43MjIzNyAzLjk0MjU4IDMuNzcxODUgMy45MDkxMSAzLjgxMzUxIDMuODY2OTJMNi44NjA2NyAwLjc4MjQ1M0M2LjkwNDA1IDAuNzQwNjk3IDYuOTM4NzIgMC42OTA1ODEgNi45NjI2NCAwLjYzNTA1OEM2Ljk4NjU2IDAuNTc5NTM2IDYuOTk5MjYgMC41MTk3MzEgNi45OTk5NyAwLjQ1OTE2OUM3LjAwMDY4IDAuMzk4NjA3IDYuOTg5NCAwLjMzODUxMyA2Ljk2Njc5IDAuMjgyNDNDNi45NDQxOCAwLjIyNjM0NyA2LjkxMDcgMC4xNzU0MSA2Ljg2ODMzIDAuMTMyNjIxQzYuODI1OTUgMC4wODk4MzIgNi43NzU1MyAwLjA1NjA1NzIgNi43MjAwNSAwLjAzMzI4NzVDNi42NjQ1NiAwLjAxMDUxNzggNi42MDUxMyAtMC4wMDA3ODYxMTYgNi41NDUyNiA0LjIzOTA4ZS0wNUM2LjQ4NTM4IDAuMDAwODcwNjYgNi40MjYyOCAwLjAxMzgxNDQgNi4zNzE0MyAwLjAzODExMDdDNi4zMTY1OCAwLjA2MjQwNzIgNi4yNjcxIDAuMDk3NTY0OSA2LjIyNTg5IDAuMTQxNTFMMy40OTYxMiAyLjkwNDk0TDAuNzY2MzQ5IDAuMTQxNTExQzAuNzI0NzU5IDAuMDk5MjQ2NyAwLjY3NTM0MiAwLjA2NTY4ODggMC42MjA5MjEgMC4wNDI3NTYxQzAuNTY2NTAxIDAuMDE5ODIzMyAwLjUwODE0NiAwLjAwNzk2NTA3IDAuNDQ5MTkyIDAuMDA3ODU5NjlDMC4zOTAyMzggMC4wMDc3NTQzMSAwLjMzMTg0MiAwLjAxOTQwNDQgMC4yNzczNDIgMC4wNDIxNDI0QzAuMjIyODQyIDAuMDY0ODgwNiAwLjE3MzMwNyAwLjA5ODI2MTEgMC4xMzE1NyAwLjE0MDM3NloiIGZpbGw9IiMyNzRGRUQiLz4KPC9zdmc+Cg==")
	// background-position: right 10px bottom 12px
	// background-repeat: no-repeat
	// background-size: 6px 6px
	&.open {
		@apply relative {}
		@apply z-10 {} }
	&:focus {
		@apply outline-none {} }
	&-input {
		@apply absolute {}
		@apply opacity-0 {}
		@apply pointer-events-none {} }
	&-display {
		@apply overflow-hidden {}
		text-overflow: ellipsis;
		white-space: nowrap; }
	&-options {
		@apply absolute {}
		@apply bg-white {}
		@apply border {}
		@apply border-blue-100 {}
		@apply flex {}
		@apply flex-col {}
		@apply invisible {}
		@apply opacity-0 {}
		@apply overflow-y-auto {}
		@apply rounded {}
		@apply text-blue-300 {}
		@apply min-w-full {}
		box-shadow: 0px 0px 35px rgba(21, 52, 175, 0.1);
		max-height: 300px;
		&.bottom {
			@apply top-full {}
			@apply mt-1 {}
			transform: translateY(30px); }
		&.left {
			@apply left-0 {} }
		&.right {
			@apply right-0 {} }
		&.top {
			@apply bottom-full {}
			@apply mb-1 {}
			transform: translateY(-30px); }
		&.active {
			@apply duration-300 {}
			@apply opacity-100 {}
			@apply visible {}
			transform: translate(0); }

		&-item {
			@apply px-4 {}
			@apply py-3 {}
			@apply text-xs {}
			@apply whitespace-no-wrap {}
			&:first-child {
				@apply rounded-tl {}
				@apply rounded-tr {} }
			&:last-child {
				@apply rounded-bl {}
				@apply rounded-br {} }
			&:not(:last-child) {
				@apply border-b {}
				@apply border-blue-100 {} }
			&:hover {
				background: rgba(blue, .04);
				color: rgba(blue, 0.8); }
			&.selected {
				@apply bg-blue-500 {}
				@apply text-white {} } }
		&-grid {
			@apply p-4 {}
			&-item {
				@apply px-2 {}
				@apply py-2 {}
				@apply rounded {}
				@apply text-center {}
				@apply text-xs {}
				&:hover {
					background: rgba(blue, .03); }
				&.selected {
					@apply bg-blue-500 {}
					@apply text-white {} } } } }
	&.custom-select {
		-webkit-appearance: none;
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNyA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC4xMzE1NyAwLjE0MDM3NkMwLjA4OTg2MDQgMC4xODI1MTkgMC4wNTY3NzE3IDAuMjMyNTY0IDAuMDM0MTk1OSAwLjI4NzY1QzAuMDExNjIwMSAwLjM0MjczNiAtMS41NzI4M2UtMDcgMC40MDE3ODMgLTEuNTQ2NzZlLTA3IDAuNDYxNDE1Qy0xLjUyMDdlLTA3IDAuNTIxMDQ2IDAuMDExNjIwMSAwLjU4MDA5MyAwLjAzNDE5NTkgMC42MzUxNzlDMC4wNTY3NzE3IDAuNjkwMjY1IDAuMDg5ODYwNSAwLjc0MDMxIDAuMTMxNTcgMC43ODI0NTNMMy4xNzg3MyAzLjg2NjkyQzMuMjIwNCAzLjkwOTExIDMuMjY5ODcgMy45NDI1OCAzLjMyNDMzIDMuOTY1NDFDMy4zNzg3OSAzLjk4ODI1IDMuNDM3MTcgNCAzLjQ5NjEyIDRDMy41NTUwOCA0IDMuNjEzNDUgMy45ODgyNSAzLjY2NzkxIDMuOTY1NDFDMy43MjIzNyAzLjk0MjU4IDMuNzcxODUgMy45MDkxMSAzLjgxMzUxIDMuODY2OTJMNi44NjA2NyAwLjc4MjQ1M0M2LjkwNDA1IDAuNzQwNjk3IDYuOTM4NzIgMC42OTA1ODEgNi45NjI2NCAwLjYzNTA1OEM2Ljk4NjU2IDAuNTc5NTM2IDYuOTk5MjYgMC41MTk3MzEgNi45OTk5NyAwLjQ1OTE2OUM3LjAwMDY4IDAuMzk4NjA3IDYuOTg5NCAwLjMzODUxMyA2Ljk2Njc5IDAuMjgyNDNDNi45NDQxOCAwLjIyNjM0NyA2LjkxMDcgMC4xNzU0MSA2Ljg2ODMzIDAuMTMyNjIxQzYuODI1OTUgMC4wODk4MzIgNi43NzU1MyAwLjA1NjA1NzIgNi43MjAwNSAwLjAzMzI4NzVDNi42NjQ1NiAwLjAxMDUxNzggNi42MDUxMyAtMC4wMDA3ODYxMTYgNi41NDUyNiA0LjIzOTA4ZS0wNUM2LjQ4NTM4IDAuMDAwODcwNjYgNi40MjYyOCAwLjAxMzgxNDQgNi4zNzE0MyAwLjAzODExMDdDNi4zMTY1OCAwLjA2MjQwNzIgNi4yNjcxIDAuMDk3NTY0OSA2LjIyNTg5IDAuMTQxNTFMMy40OTYxMiAyLjkwNDk0TDAuNzY2MzQ5IDAuMTQxNTExQzAuNzI0NzU5IDAuMDk5MjQ2NyAwLjY3NTM0MiAwLjA2NTY4ODggMC42MjA5MjEgMC4wNDI3NTYxQzAuNTY2NTAxIDAuMDE5ODIzMyAwLjUwODE0NiAwLjAwNzk2NTA3IDAuNDQ5MTkyIDAuMDA3ODU5NjlDMC4zOTAyMzggMC4wMDc3NTQzMSAwLjMzMTg0MiAwLjAxOTQwNDQgMC4yNzczNDIgMC4wNDIxNDI0QzAuMjIyODQyIDAuMDY0ODgwNiAwLjE3MzMwNyAwLjA5ODI2MTEgMC4xMzE1NyAwLjE0MDM3NloiIGZpbGw9IiMyNzRGRUQiLz4KPC9zdmc+Cg==");
		background-position: right 10px bottom 12px;
		background-repeat: no-repeat;
		background-size: 6px 6px; } }

.button {
	@apply inline-block {}
	@apply duration-300 {}
	@apply py-4 {}
	@apply px-10 {}
	@apply rounded-sm {}
	@apply text-sm {}
	outline: none !important; }


.checkbox {
	@apply inline-flex {}
	@apply items-center {}
	@apply cursor-pointer {}

	.input {
		@apply hidden {} }

	.label {
		@apply text-xl {}
		@apply text-gray-600 {}
		height: 24px; } }

.card {
	@apply rounded {}
	@apply px-6 {}
	@apply py-6 {}
	@apply relative {}

	&-tag {
		@apply absolute {}
		@apply block {}
		height:  calc(theme('spacing.6') - 4px);
		left: 0;
		top:  calc(theme('spacing.6') + 2px);
		width: 3px; } }


.dropdown {
	@apply relative {}

	&-menu {
		@apply absolute {}
		@apply bg-white {}
		// @apply border
		@apply border-blue-100 {}
		@apply duration-300 {}
		@apply flex {}
		@apply flex-col {}
		@apply invisible {}
		@apply min-w-full {}
		@apply opacity-0 {}
		@apply rounded {}
		@apply text-blue-300 {}
		box-shadow: 0px 0px 35px rgba(21, 52, 175, 0.05);
		top: 100%;
		transform: translateY(20px);
		z-index: 1000;
		&.bottom {
			top: 100%;
			bottom: auto; }
		&.top {
			bottom: 100%;
			top: auto; }

		&.left {
			right: 0; }
		// &.right
		// 	left: 0

		&-item {
			@apply flex {}
			@apply flex-row {}
			@apply items-center {}
			@apply text-xs {}
			@apply px-6 {}
			@apply py-4 {}
			&:not(:last-child) {
				@apply border-b {}
				@apply border-blue-100 {} }

			.icon {
				@apply text-center {}
				@apply text-lg {}
				@apply w-4 {} }
			.text {
				@apply ml-3 {}
				white-space: nowrap; } } }
	&.open {
		.dropdown-menu {
			@apply opacity-100 {}
			@apply visible {}
			transform: translateY(0); } } }


.modal {
	@apply cursor-pointer {}
	@apply duration-500 {}
	@apply fixed {}
	@apply flex {}
	@apply flex-col {}
	@apply inset-0 {}
	@apply invisible {}
	@apply opacity-0 {}
	@apply overflow-y-auto {}
	background: rgba(#000, .3);
	padding: 20px 0;
	z-index: 1000000;
	&-content {
		@apply cursor-default {}
		@apply shadow-sm {}
		@apply bg-white {}
		@apply m-auto {}
		@apply rounded-lg {}
		@apply px-16 {}
		@apply py-16 {}
		@apply relative {} }
	&-close {
		@apply absolute {}
		@apply opacity-50 {}
		@apply right-6 {}
		@apply text-blue-500 {}
		@apply top-5 {}
		height: 20px;
		font-size: 20px;
		width: 20px;
		&:focus {
			@apply outline-none {} } }
	&.open {
		@apply opacity-100 {}
		@apply visible {}
		.modal-content {
			animation: drop 1s 1 linear;
			animation-fill-mode: forwards;
			transform: matrix(1,0,0,1,0,49.75);
			opacity: 0; } } }


.table {
	@apply table-auto {}
	@apply w-full {}
	@apply text-left {}

	thead {
		tr {
			@apply bg-blue-100 {}
			@apply border-b {}
			@apply border-blue-100 {}

			th {
				@apply px-4 {}
				@apply py-5 {}
				@apply font-medium {}
				@apply text-xs {}

				&:first-child {
					@apply pl-8 {} }

				&:last-child {
					@apply pr-8 {} } } } }
	tbody {
		tr {
			&.selected {
				@apply bg-gray-200 {}

				td {
					&:first-child {
						@apply relative {}

						&:before {
							@apply absolute {}
							@apply bg-blue-500 {}
							@apply block {}
							@apply left-0 {}
							content: '';
							height: 15px;
							top: 50%;
							transform: translateY(-50%);
							width: 3px; } }

					&:last-child {
						@apply relative {}

						&:after {
							@apply absolute {}
							@apply block {}
							@apply text-gray-600 {}
							border-bottom: 2px solid;
							border-left: 2px solid;
							content: '';
							height: 10px;
							right: 10px;
							top: 50%;
							transform: translateY(-50%) rotate(-135deg);
							width: 10px; } } } }

			&:not(:last-child) {
				@apply border-b {}
				@apply border-blue-200 {} }
			td {
				@apply px-4 {}
				@apply py-2 {}
				@apply text-sm {}
				height: 60px;
				&:first-child {
					@apply pl-8 {} }
				&:last-child {
					@apply pr-8 {} } } } }


	&.flush {
		th, td {
			@apply py-4 {}
			&:first-child {
				@apply pl-0 {} }
			&:last-child {
				@apply pr-0 {} } } }

	&-responsive {
		@apply overflow-x-auto {}
		@apply w-full {}
		.table {
			white-space: nowrap; } }
	&-interactable {
		tbody {
			tr:not(.empty) {
				@apply cursor-pointer {}
				&:hover {
					@apply bg-gray-200 {} } } } }
	// .dropdown
	// 	@apply static
	// 	&.open,
	// 	&:hover
 }	// 		@apply relative


.tabs {
	@apply flex {}
	@apply flex-row {}
	@apply flex-wrap {}
	@apply relative {}
	> li {
		@apply border-b-2 {}
		@apply border-gray-300 {}
		> a {
			@apply block {}
			@apply duration-300 {}
			@apply rounded-t {}
			@apply py-4 {}
			@apply px-4 {}
			@apply text-sm {}
			color: #A8B3E1;
			&.active {
				@apply bg-blue-100 {}
				@apply text-blue-500 {} } } }
	&-border {
		@apply bg-blue-500 {}
		@apply absolute {}
		@apply duration-300 {}
		bottom: 0;
		height: 2px;
		left: 50%;
		transform: translateX(-50%); } }


.link {
	@apply relative {}
	&:after {
		@apply absolute {}
		@apply bg-blue-500 {}
		@apply duration-300 {}
		@apply mt-1 {}
		@apply rounded-full {}
		content: '';
		height: 2px;
		left: 50%;
		transform: translateX(-50%);
		top: 100%;
		width: 0; }

	&.active,
	&:hover {
		@apply text-blue-500 {} }

	&.active {
		&:after {
			width: 10px; } } }


.caret {
	@apply inline-block {}
	border-bottom: 2px solid;
	border-left: 2px solid;
	height: 10px;
	transform: rotate(-45deg);
	width: 10px;
	&-xs {
		height: 5px;
		width: 5px; }
	&-sm {
		height: 8px;
		width: 8px; }
	&-1 {
		border-bottom-width: 1px;
		border-left-width: 1px; }
	&-2 {
		border-bottom-width: 2px;
		border-left-width: 2px; }
	&-3 {
		border-bottom-width: 3px;
		border-left-width: 3px; }
	&-right {
		transform: rotate(-135deg); }
	&-left {
		transform: rotate(45deg); }
	&-top {
		transform: rotate(135deg); } }


.loader-icon {
	animation: spin 1s linear infinite;
	border: 2px solid theme('blue.500');
	border-bottom-color: transparent;
	border-radius: 50%;
	height: 20px !important;
	width: 20px !important; }

.sm-loader {
	@apply inline-flex {}
	@apply flex-row {}
	@apply items-center {}
	&-dot {
		@apply bg-blue-500 {}
		@apply h-10px {}
		@apply mr-2 {}
		@apply rounded-lg {}
		@apply w-10px {}
		animation: zoom-animation 1s infinite;
		&:nth-child(2) {
			animation-delay: .15s; }
		&:nth-child(3) {
			animation-delay: .3s; } } }


.dropzone {
	@apply border {}
	@apply border-blue-200 {}
	@apply border-dashed {}
	@apply flex {}
	@apply items-center {}
	@apply justify-center {}
	@apply mb-10 {}
	@apply px-10 {}
	@apply py-10 {}
	@apply relative {}
	@apply rounded {}
	@apply text-center {}
	@apply w-full {} }


.custom-chart {
	position: relative;
	width: 100%; }
.custom-bar-chart {
	.custom-chart {
		&-content {
			margin-left: auto;
			margin-bottom: 50px;
			position: relative;
			width: calc(100% - 30px); } }
	.grid {
		&-lines {
			display: flex;
			height: 100%;
			justify-content: space-between;
			left: 0;
			pointer-events: none;
			position: absolute;
			top: 0;
			width: 100%;
			&-horizontal {
				flex-flow: column-reverse;
				.grid-line {
					background-image: linear-gradient(to right, transparent 50%, theme('colors.blue.100') 50%);
					background-size: 20px 100%;
					height: 2px;
					position: relative;
					width: 100%;
					&-label {
						font-size: .65rem;
						opacity: .2;
						position: absolute;
						right: 100%;
						top: 50%;
						transform: translateY(-50%); } } }
			&-vertical {
				flex-flow: row;
				.grid-line {
					background-image: linear-gradient(to bottom, transparent 50%, theme('colors.blue.100') 50%);
					background-size: 100% 20px;
					height: 100%;
					width: 2px; } } } }

	.bars {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		left: 0;
		height: 100%;
		padding-left: 20px;
		position: absolute;
		top: 0;
		width: 100%;
		.bar {
			align-items: flex-end;
			display: flex;
			height: 100%;
			justify-content: center;
			padding: 0 10px;
			&-label {
				font-size: .65rem;
				margin-top: 20px;
				opacity: .2;
				position: absolute;
				text-align: center;
				top: 100%;
				white-space: nowrap; }
			&-tip {
				background: #fff;
				border: 1px solid theme('colors.blue.100');
				border-radius: .35rem;
				bottom: 100%;
				box-shadow: 0 3px 6px rgba(#000, .05);
				color: rgba(#000, .3);
				font-size: .65rem;
				font-weight: 600;
				left: 50%;
				margin-bottom: 10px;
				opacity: 0;
				padding: 5px 15px;
				position: absolute;
				text-align: center;
				transform: translate(-50%);
				white-space: nowrap;
				visibility: hidden;
				&-title {
					font-size: 1.2em;
					color: rgba(#000, .5); } }
			&-visual {
				background-color: theme('colors.blue.700');
				border-radius: 999px;
				bottom: 0;
				cursor: pointer;
				// min-height: 10px
				position: relative;
				transition: .3s;
				width: 5px; }
			&:hover {
				.bar-visual {
					background-color: theme('colors.blue.200'); }
				.bar-tip {
					opacity: 1;
					visibility: visible; } } } } }

.card-slider {
	@apply relative {}
	@apply w-full {}
	&-item {
		@apply bg-white {}
		@apply absolute {}
		top: 50%;
		transform: translateY(-50%);
		transform-origin: left;
		&:not(.active) {
			@apply hidden {} } }
	&-button {
		@apply absolute {}
		@apply bg-blue-200 {}
		@apply cursor-pointer {}
		@apply flex {}
		@apply items-center {}
		@apply justify-center {}
		@apply h-25px {}
		@apply rounded-full {}
		@apply text-white {}
		@apply w-25px {}
		top: 50%;
		transform: translateY(-50%);
		&-left {
			@apply left-0 {}
			@apply pl-1 {} }
		&-right {
			@apply right-0 {}
			@apply pr-1 {} } } }


.sk-cube-grid {
	width: 100px;
	height: 100px;
	margin: 100px auto;
	.sk-cube {
		width: 33%;
		height: 33%;
		background-color: theme('colors.blue.500');
		float: left;
		-webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
		animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; }

	.sk-cube1 {
		-webkit-animation-delay: 0.2s;
		animation-delay: 0.2s;
		border-top-left-radius: 13px; }

	.sk-cube2 {
		-webkit-animation-delay: 0.3s;
		animation-delay: 0.3s; }

	.sk-cube3 {
		-webkit-animation-delay: 0.4s;
		animation-delay: 0.4s;
		border-top-right-radius: 13px; }

	.sk-cube4 {
		-webkit-animation-delay: 0.1s;
		animation-delay: 0.1s; }

	.sk-cube5 {
		-webkit-animation-delay: 0.2s;
		animation-delay: 0.2s; }

	.sk-cube6 {
		-webkit-animation-delay: 0.3s;
		animation-delay: 0.3s; }

	.sk-cube7 {
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
		border-bottom-left-radius: 13px; }

	.sk-cube8 {
		-webkit-animation-delay: 0.1s;
		animation-delay: 0.1s; }

	.sk-cube9 {
		-webkit-animation-delay: 0.2s;
		animation-delay: 0.2s;
		border-bottom-right-radius: 13px; } }

@-webkit-keyframes sk-cubeGridScaleDelay {
	0%, 70%, 100% {
		-webkit-transform: scale3D(1, 1, 1);
		transform: scale3D(1, 1, 1); }

	35% {
		-webkit-transform: scale3D(0, 0, 1);
		transform: scale3D(0, 0, 1); } }

@keyframes sk-cubeGridScaleDelay {
	0%, 70%, 100% {
		-webkit-transform: scale3D(1, 1, 1);
		transform: scale3D(1, 1, 1); }

	35% {
		-webkit-transform: scale3D(0, 0, 1);
		transform: scale3D(0, 0, 1); } }

.vdp-datepicker {
	.vdp-datepicker {
		&__calendar {
			animation: fadeInUp .5s;
			border: 1px solid theme('colors.blue.500');
			box-shadow: 0 5px 7px rgba(#000, .1);
			border-radius: .35rem;
			padding: .5rem .35rem;
			margin-top: 1rem;
			width: 320px;
			header {
				span {
					border-radius: .35rem;
					&:not(.disabled):hover {
						background: #e1ebfb;
						color: theme('colors.blue.500'); }
					&.day__month_btn,
					&.month__year_btn,
					&:not(:first-child):not(:last-child) {
						font-size: 12px;
						font-weight: 400; }
					&.prev {
						&:after {
							border-right-color: theme('colors.blue.500'); } }
					&.next {
						&:after {
							border-left-color: theme('colors.blue.500'); } } } }
			.cell {
				border-radius: .35rem;
				color: rgba(darken(#274FED, 30%), .5);
				font-size: 12px;
				font-weight: 400;
				&.day {
					margin: 2px;
					width: calc(14.285714285714286% - 4px); }
				&:not(.blank):not(.disabled):not(.weekend) {
					&.day,
					&.month,
					&.year {
						&:hover,
						&.today {
							border-color: transparent;
							background: #e1ebfb; } } }
				&.day-header {
					// color: $body-color
					&:first-child,
					&:nth-child(7) {
						color: theme('colors.orange.500'); } }
				&.weekend {
					color: theme('colors.orange.500');
					&:not(.blank):not(.disabled) {
						&.day,
						&.month,
						&.year {
							&:hover,
							&.today {
								border-color: transparent !important;
								background: rgba(#FF5810, .15); } } } }
				&.selected {
					background-color: theme('colors.blue.500');
					color: #fff;
					font-weight: 900;
					&:not(.blank):not(.disabled) {}
					&.day,
					&.month,
					&.year {
						&:hover,
						&.today {
							background: theme('colors.blue.500') !important; } } } } } } }


.company-tag,
.company-user-tag {
	@apply px-2 {}
	@apply py-2 {}
	@apply rounded-sm {}
	font-size: .65rem; }

.company-tag {
	@apply bg-green-100 {}
	@apply text-green-500 {} }
.company-user-tag {
	@apply bg-orange-100 {}
	@apply text-orange-500 {} }

.self-y-center {
	top: 50%;
	transform: translateY(-50%); }

.self-x-center {
	left: 50%;
	transform: translateX(-50%); }

.self-xy-center {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%); }

.spin {
	animation: spin 1s linear infinite; }

.collapse {
	display: none; }

[disabled] {
	opacity: .5;
	pointer-events: none; }


.app-layout {
	@apply relative {}
	@apply min-h-screen {}
	.sidebar {
		@apply fixed {}
		@apply bg-white {}
		@apply duration-300 {}
		@apply h-full {}
		@apply flex {}
		@apply flex-col {}
		@apply left-0 {}
		@apply py-24 {}
		@apply pb-6 {}
		@apply text-gray-500 {}
		padding-left: 20px;
		padding-right: 20px;
		width: $sidebar-width;
		box-shadow: inset -1px 4px 24px rgba(39, 79, 237, 0.08);
		// box-shadow: 5px 5px 24px rgba(39, 79, 237, 0.08);
		z-index: 10000;
		&-toggle {
			@apply block {}
			@apply cursor-pointer {}
			@apply flex {}
			@apply flex-col {}
			@apply h-20px {}
			@apply justify-between {}
			@apply mr-5 {}
			@apply w-30px {}
			@media (min-width: theme('screens.lg')) {
				@apply hidden {} }
			.line {
				@apply bg-blue-200 {}
				@apply h-2px {}
				@apply rounded {}
				@apply w-full {} } }
		@media (max-width: theme('screens.lg')) {
			@apply fixed {}
			transform: translateX(-100%); }

		.logo {
			@apply absolute {}
			@apply flex {}
			@apply items-end {}
			@apply pl-5 {}
			top: theme('spacing.6');
			left: theme('spacing.6');
			right: theme('spacing.6'); }

		.nav {
			@apply flex {}
			@apply flex-col {}
			@apply mb-10 {}
			&-title {
				// @apply font-hairline
				@apply mb-4 {}
				// @apply opacity-25
				@apply px-5 {}
				@apply text-xs {}
				@apply uppercase {} }
			&-item {
				// &:not(:first-child)
				/// margin-top: -5px
				a {
					@apply flex {}
					@apply duration-300 {}
					@apply px-5 {}
					@apply py-4 {}
					@apply rounded {}
					color: #252A32;
					font-size: 12px;
					&:hover {
						background: #f4f6fa; }

					&.active {
						background-color: #f4f6fa;
						@apply text-gray-500 {} } } }
			.sub-nav {
				@apply pl-8 {} } }
		&.staff {
			@apply bg-white {}
			@apply border-r {}
			@apply border-blue-200 {}
			.nav {
				&-title {
					@apply text-black {} }
				&-item {
					a {
						@apply text-gray-600 {} } } } } }

	.app-content {
		@apply absolute {}
		@apply duration-300 {}
		@apply h-full {}
		@apply px-10 {}
		@apply pt-24 {}
		@apply right-0 {}
		@apply overflow-x-hidden {}
		@apply overflow-y-auto {}
		width: calc(100% - #{$sidebar-width});
		// box-shadow: 5px 5px 24px rgba(39, 79, 237, 0.08);
		@media (max-width: theme('screens.lg')) {
			width: 100%; }
		@media (max-width: 425px) {
			@apply px-6 {} }

		.navbar {
			@apply bg-white {}
			@apply duration-300 {}
			@apply fixed {}
			@apply flex {}
			@apply flex-row {}
			@apply items-center {}
			@apply py-2 {}
			@apply px-10 {}
			left: $sidebar-width;
			right: 0;
			top: 0;
			z-index: 10000;
			.dropdown.open {
				[dropdown] {
					@apply bg-blue-100 {} } }
			@media (max-width: theme('screens.lg')) {
				left: 0; }
			@media (max-width: 425px) {
				@apply px-6 {} } } } }


.icon-thick {
	font-size: 30px;
	&:host icon-inner svg path {
			stroke-width: 56px; } }


.animation-delay {
	@for $i from 0 through 10 {
		&-#{$i*100} {
			animation-delay: unquote('#{$i/10}s'); } } }


.transition-delay {
	@for $i from 0 through 10 {
		&-#{$i*100} {
			transition-delay: unquote('#{$i/10}s'); } } }


#app.sidebar-open {
	.app-content {
		@apply overflow-hidden {} }
	@media (max-width: theme('screens.lg')) and (min-width: 301px) {
		.sidebar {
			transform: translatex(0); }
		.app-content {
			transform: translatex($sidebar-width); } }
	@media (max-width: $sidebar-width) {
		.sidebar {
			transform: translatex(0); }
		.app-content {
			transform: translatex(calc(100% - 75px)); } } }

.pre-loader {
	&-animation {
		@apply duration-300 {} }
	&.hide {
		@apply duration-300 {}
		@apply opacity-0 {}
		@apply invisible {}
		transition-delay: .3s;
		.pre-loader-animation {
			@apply opacity-0 {}
			transform: scale(.5); } } }


.pagination {
	@apply border {}
	@apply border-blue-200 {}
	@apply flex-wrap {}
	@apply inline-flex {}
	@apply rounded {}
	@apply p-1 {}
	.page-link {
		@apply cursor-pointer {}
		@apply block {}
		@apply text-sm {}
		@apply px-3 {}
		@apply py-1 {}
		@apply rounded {}
		@apply text-blue-400 {}
		&:hover {
			@apply bg-blue-200 {}
			@apply text-white {} } }
	.page-item {
		&:not(:last-child) {
			@apply mr-1 {} }
		&.active {
			.page-link {
				@apply bg-blue-500 {}
				@apply text-white {} } } } }


.credit-card {
	@apply duration-300 {}
	@apply inline-block {}
	@apply relative {}
	@apply uppercase {}
	@media (min-width: 1200px) {
		transform-origin: left; }
	&-container {
		@apply absolute {}
		@apply flex {}
		@apply flex-col {}
		@apply justify-between {}
		@apply top-0 {}
		@apply text-white {}
		left: 10%;
		right: 10%;
		top: 8%;
		bottom: 10%; }
	&-corporate {
		@apply inline-block {}
		@apply relative {}
		font-size: 6.5px;
		color: #799DF7;
		letter-spacing: 1px; }
	&-name {
		@apply inline-block {}
		@apply relative {}
		font-size: 13px; }
	&-expiry {
		@apply inline-block {}
		@apply relative {}
		color: #799DF7;
		font-size: 6.6px;
		letter-spacing: 1px; }

	img {
		height: auto !important;
		max-height: 200px;
		max-width: 100%;
		width: auto !important;
		color: #799DF7; }

	// &:not(.active-card)
	// 	opacity: .75
 }	// 	transform: scale(.85)


.shadow-purple {
	box-shadow: 5px 5px 10px theme('colors.purple.300'); }

.shadow-blue {
	box-shadow: 5px 5px 10px theme('colors.blue.300'); }

.shadow-blue {
	box-shadow: 5px 5px 10px theme('colors.blue.300'); }



.btn {
	@apply duration-300 {}
	@apply inline-flex {}
	@apply items-center {}
	@apply justify-center {}
	@apply py-3 {}
	@apply px-6 {}
	@apply rounded-sm {}
	@apply text-sm {}
	border: 1px solid transparent;
	outline: none !important;
	&-md {
		@apply py-4 {}
		@apply px-10 {} }
	&-sm {
		@apply text-xs {}
		@apply px-4 {}
		@apply py-2 {} }
	&-xs {
		@apply text-xs {}
		@apply px-4 {}
		padding-bottom: 5px;
		padding-top: 5px; }
	@each $color, $map in $colors {
		&-#{$color} {
			background: map-get($map, 500);
			border-color: map-get($map, 500);
			color: map-get($map, 'opposite');
			&:hover {
				background: map-get($map, 600);
				border-color: map-get($map, 600); }
			&-outline {
				background: map-get($map, 'opposite');
				@if $color == 'gray' {
					color: map-get($map, 700);
					border: 1px solid map-get($map, 300); }
				@else {
					color: map-get($map, 500);
					border: 1px solid map-get($map, 500); }
				&:hover {
					background: map-get($map, 500);
					border-color: map-get($map, 500);
					color: map-get($map, 'opposite'); } }
			&-soft {
				background: map-get($map, 100);
				@if $color == 'gray' {
					color: map-get($map, 700); }
				@else {
					color: map-get($map, 500); }
				&:hover {
					background: map-get($map, 500);
					color: map-get($map, 'opposite'); } } } }

	&-black {
		background: #222;
		color: #fff;
		&-outline {
			background: #fff;
			border: 1px solid #222;
			color: #222; }
		&-soft {
			background: #222;
			color: #999; } } }

.badge {
	@apply inline-flex {}
	@apply items-center {}
	@apply py-2 {}
	@apply px-4 {}
	@apply rounded-sm {}
	@apply text-sm {}
	border: 1px solid transparent;
	outline: none !important;
	&-xs {
		@apply text-8 {}
		@apply py-1 {}
		@apply px-2 {} }
	&-dot {
		@apply inline-flex {}
		@apply items-center {}
		@apply justify-center {}
		border-radius: 999px;
		height: 20px;
		padding: 0;
		width: 20px; }
	@each $color, $map in $colors {
		&-#{$color} {
			background: map-get($map, 500);
			color: map-get($map, 'opposite');
			&-outline {
				background: map-get($map, 'opposite');
				@if $color == 'gray' {
					border: 1px solid map-get($map, 300);
					color: map-get($map, 700); }
				@else {
					border: 1px solid map-get($map, 500);
					color: map-get($map, 500); } }
			&-soft {
				background: map-get($map, 200);
				@if $color == 'gray' {
					color: map-get($map, 700); }
				@else {
					color: map-get($map, 500); }
				&-outline {
					background: map-get($map, 100);
					@if $color == 'gray' {
						border: 1px solid map-get($map, 300);
						color: map-get($map, 700); }
					@else {
						color: map-get($map, 400);
						border: 1px solid map-get($map, 200); } } } } } }


.alert {
	@apply flex {}
	@apply flex-row {}
	@apply font-normal {}
	@apply items-center {}
	@apply rounded {}
	@apply p-3 {}
	@apply text-sm {}
	@each $color, $map in $colors {
		&-#{$color} {
			background: map-get($map, 500);
			color: map-get($map, 'opposite');
			.alert-icon {
				background: map-get($map, 'opposite');
				color: map-get($map, 500); }
			&-outline {
				background: map-get($map, 'opposite');
				@if $color == 'gray' {
					color: map-get($map, 700);
					border: 1px solid map-get($map, 300); }
				@else {
					color: map-get($map, 500);
					border: 1px solid map-get($map, 500); }
				.alert-icon {
					background: map-get($map, 500);
					color: map-get($map, 'opposite'); } }
			&-soft {
				background: map-get($map, 200);
				@if $color == 'gray' {
					color: #000; }
				@else {
					color: #000; }
				.alert-icon {
					background: map-get($map, 500);
					color: map-get($map, 'opposite'); } } } }
	&-icon {
		@apply inline-flex {}
		@apply items-center {}
		@apply justify-center {}
		@apply rounded-50 {}
		@apply mr-3 {}
		@apply text-xs {}
		height: 20px;
		flex: 0 0 20px;
		width: 20px; } }



.cp {
	&-card {
		@apply relative {}
		@apply text-white {}
		// padding-bottom: 65%
		width: 100%;
		&-bg {
			@apply absolute {}
			@apply w-full {} }
		&-content {
			@apply absolute {}
			@apply flex {}
			@apply flex-col {}
			@apply h-full {}
			@apply items-start {}
			@apply w-full {}
			left: 0;
			padding: 8% 10% 5%;
			top: 0; }
		&-provider {
			width: 20%; }
		&-pan {
			@apply mt-auto {}
			font-size: 1.3vw;
			letter-spacing: .1em; }
		&-expiry {
			@apply opacity-50 {}
			font-weight: 100;
			letter-spacing: .1em; }
		&-action {
			@apply flex {}
			@apply flex-col {}
			@apply justify-around {}
			@apply items-center {}
			@apply opacity-0 {}
			background: rgba(#fff, .1);
			border-radius: 5px;
			cursor: pointer;
			height: 30px;
			padding: 6px;
			width: 30px;
			&:focus {
				outline: none; }
			.dropdown.open & {
				@apply opacity-100 {} }
			.dot {
				@apply block {}
				background: #fff;
				border-radius: 999px;
				height: 4px;
				opacity: .5;
				width: 4px; } }
		&:hover {
			.cp-card-action {
				@apply opacity-100 {} } } } }



.icon {
	&-bars {
		@apply inline-flex {}
		@apply items-end {}
		height: .8em;
		vertical-align: middle;
		.bar {
			@apply h-full {}
			@apply rounded-full {}
			background: currentColor;
			width: 2px;
			&:not(:last-child) {
				margin-right: .25em; }
			&:first-child {
				height: calc(100%/3*1.5); }
			&:nth-child(2) {
				height: calc(100%/3*2.5); } } } }


.progress-bar {
	@apply flex {}
	@apply h-5px {}
	@apply overflow-hidden {}
	@apply rounded-full {}
	@apply w-full {}
	.progress {
		@apply duration-100 {}
		@apply h-full {}
		@apply overflow-hidden {}
		@apply rounded-full {}
		min-width: 10px; }
	@each $color, $map in $colors {
		&-#{$color} {
			background: map-get($map, 200);
			.progress {
				background: map-get($map, 500);
				color: map-get($map, 'opposite'); } } } }


.grayscale {
	filter: unquote('grayscale()'); }

.custom-slider {
	@apply block {}
	&-wrapper {
		@apply overflow-x-hidden {}
		@apply whitespace-no-wrap {} }
	&-item {
		@apply duration-300 {}
		@apply inline-block {}
		// &:not(:first-child)
		/// margin-left: 10px
		&:not(.active) {
			@apply opacity-50 {}
			transform: scale(.9);
			// transform-origin: left center
			// &.transform-right
 } }			/// transform-origin: right center

	&-button {
		@apply absolute {}
		@apply bg-white {}
		@apply cursor-pointer {}
		@apply duration-100 {}
		@apply flex {}
		@apply items-center {}
		@apply invisible {}
		@apply justify-center {}
		@apply opacity-0 {}
		@apply rounded-full {}
		height: 30px;
		top: 50%;
		transition-property: {}
		transform: translateY(-50%) scale(0);
		z-index: 1000;
		width: 30px;
		&:focus {
			outline: none; }
		&.show {
			@apply visible {}
			@apply opacity-100 {}
			transform: translateY(-50%) scale(1);
			// &:active
 }			/// transform: translateY(-50%) scale(.8)
		&.prev {
			@apply left-0 {}
			@apply ml-10 {} }
		&.next {
			@apply right-0 {}
			@apply mr-10 {} } } }

.custom-switch {
	@apply cursor-pointer {}
	@apply relative {}
	line-height: 0;
	&-label {
		@apply bg-blue-900 {}
		@apply duration-300 {}
		@apply inline-block {}
		@apply rounded-full {}
		@apply relative {}
		height: 24px;
		width: 40px;
		&:before {
			@apply absolute {}
			@apply bg-white {}
			@apply block {}
			@apply duration-300 {}
			@apply rounded-full {}
			content: '';
			height: 16px;
			left: 4px;
			top: 50%;
			transform: translateY(-50%);
			width: 16px; } }
	&-input {
		@apply hidden {}
		&:checked + .custom-switch-label {
			@apply bg-blue-500 {}
			&:before {
				left: calc(100% - 16px - 4px); } } } }

.h-app {
	height: calc(100vh - 60px); }

.search-input {
	@apply relative {}
	input {
		@apply border {}
		@apply border-solid {}
		@apply border-blue-200 {}
		@apply rounded {}
		// @apply placeholder-blue-200
		@apply outline-none {}
		@apply pl-10 {}
		@apply pr-2 {}
		@apply py-3 {}
		@apply text-xs {}
		@apply w-full {} }
	&-icon {
		@apply text-blue-200 {}
		@apply absolute {}
		@apply z-10 {}
		@apply text-lg {}
		@apply ml-3 {}
		@apply left-0 {} } }



.vdp-datepicker.input {
	@apply p-0 {}
	border: none;
	input {
		@apply bg-transparent {}
		@apply block {}
		@apply cursor-pointer {}
		@apply duration-300 {}
		@apply pb-2 {}
		@apply px-10 {}
		@apply text-sm {}
		@apply rounded-none {}
		@apply w-full {}
		-webkit-appearance: none;
		border-bottom: 1px solid #E0E5FC;
		outline: none;
		&:-webkit-autofill,
		&:-webkit-autofill:hover {}
		&:-webkit-autofill:focus {
			@apply bg-transparent {} } } }

.signup-container {
	.form-group {
		@apply mb-8 {} } }

.filter-date {
	.vdp-datepicker input {
	 	@apply -mt-6 {} }
	input {
		border-bottom: 3px solid #E0E5FC !important; } }

.payment-method {
	.icon-right {
		top: 57%;
		right: 4%; } }

.table tbody tr th:first-child {
    padding-left: 2rem; }

.dashboard-card {
	width: 250px; }

.form-group-2 {
  @apply mb-6 {}
  @apply relative {}
  .label {
    @apply absolute {}
    @apply bg-white {}
    @apply duration-300 {}
    @apply pointer-events-none {}
    color: #333;
    font-size: 12px;
    font-weight: 200;
    left: 21px;
    top: 16px; }
  &-text {
    @apply mt-1 {}
    @apply text-xs {} }
  .input {
    @apply block {}
    @apply w-full {}
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    color: #222222;
    font-weight: 200;
    font-size: 12px;
    height: 50px;
    padding: 15px 20px;
    @media screen and (device-aspect-ratio: 2/3) {
      font-size: 16px; }
    @media screen and (device-aspect-ratio: 40/71) {
      font-size: 16px; }
    @media screen and (device-aspect-ratio: 375/667) {
      font-size: 16px; }
    @media screen and (device-aspect-ratio: 9/16) {
      font-size: 16px; }
    @media screen and (device-aspect-ratio: 375/812) {
      font-size: 16px; }
    &:disabled {
      @apply bg-white {} }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      background-color: white !important;
      font-weight: 200;
 }      // transition: background-color 5000s ease-in-out
    &::placeholder {
      color: #666666; }
    &:focus {
      @apply border-blue-500 {}
      @apply outline-none {}
      box-shadow: 0px 0px 15px rgba(39, 79, 237, 0.1); }
    &.select {
      @apply relative {}
      background-color: #fff;
      // background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOSA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC4xNjkxNjIgMC4xNzU0N0MwLjExNTUzNSAwLjIyODE0OCAwLjA3Mjk5MjIgMC4yOTA3MDUgMC4wNDM5NjYxIDAuMzU5NTYzQzAuMDE0OTQwMSAwLjQyODQyMSAtMS45NjYwNGUtMDcgMC41MDIyMjkgLTEuOTMzNDZlLTA3IDAuNTc2NzY4Qy0xLjkwMDg3ZS0wNyAwLjY1MTMwOCAwLjAxNDk0MDEgMC43MjUxMTcgMC4wNDM5NjYyIDAuNzkzOTc1QzAuMDcyOTkyMiAwLjg2MjgzMyAwLjExNTUzNSAwLjkyNTM4OCAwLjE2OTE2MiAwLjk3ODA2Nkw0LjA4Njk0IDQuODMzNjVDNC4xNDA1MSA0Ljg4NjM4IDQuMjA0MTIgNC45MjgyMiA0LjI3NDE0IDQuOTU2NzZDNC4zNDQxNiA0Ljk4NTMxIDQuNDE5MjIgNSA0LjQ5NTAxIDVDNC41NzA4MSA1IDQuNjQ1ODcgNC45ODUzMSA0LjcxNTg5IDQuOTU2NzZDNC43ODU5MSA0LjkyODIyIDQuODQ5NTIgNC44ODYzOCA0LjkwMzA5IDQuODMzNjVMOC44MjA4NyAwLjk3ODA2NkM4Ljg3NjYzIDAuOTI1ODcxIDguOTIxMjEgMC44NjMyMjYgOC45NTE5NyAwLjc5MzgyM0M4Ljk4MjcyIDAuNzI0NDIgOC45OTkwNCAwLjY0OTY2NCA4Ljk5OTk2IDAuNTczOTYxQzkuMDAwODggMC40OTgyNTkgOC45ODYzNyAwLjQyMzE0MSA4Ljk1NzMgMC4zNTMwMzdDOC45MjgyNCAwLjI4MjkzNCA4Ljg4NTE5IDAuMjE5MjYzIDguODMwNyAwLjE2NTc3NkM4Ljc3NjIyIDAuMTEyMjkgOC43MTE0IDAuMDcwMDcxOCA4LjY0MDA2IDAuMDQxNjA5NEM4LjU2ODcyIDAuMDEzMTQ3NSA4LjQ5MjMxIC0wLjAwMDk4MjY1NiA4LjQxNTMzIDUuMzAzNzllLTA1QzguMzM4MzUgMC4wMDEwODg3MyA4LjI2MjM2IDAuMDE3MjY4MyA4LjE5MTg0IDAuMDQ3NjM4NUM4LjEyMTMyIDAuMDc4MDA5MyA4LjA1NzcgMC4xMjE5NTYgOC4wMDQ3MiAwLjE3Njg4OUw0LjQ5NTAxIDMuNjMxMTdMMC45ODUzMDYgMC4xNzY4ODlDMC45MzE4MzMgMC4xMjQwNTkgMC44NjgyOTYgMC4wODIxMTEzIDAuNzk4MzI4IDAuMDUzNDQ1M0MwLjcyODM1OSAwLjAyNDc3OTMgMC42NTMzMzEgMC4wMDk5NTY4MSAwLjU3NzUzMyAwLjAwOTgyNDczQzAuNTAxNzM1IDAuMDA5NjkzMTIgMC40MjY2NTQgMC4wMjQyNTU3IDAuMzU2NTgzIDAuMDUyNjc4MUMwLjI4NjUxMSAwLjA4MTEwMDkgMC4yMjI4MjQgMC4xMjI4MjcgMC4xNjkxNjIgMC4xNzU0N1oiIGZpbGw9IiMyMjIyMjIiLz4KPC9zdmc+Cg==")
      background-position: right 20px bottom 15px;
      background-repeat: no-repeat;
      background-size: 12px 14px;
      &.open {
        // background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOSA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNOC44MzA4NCA0LjgyNDUzQzguODg0NDYgNC43NzE4NSA4LjkyNzAxIDQuNzA5MjkgOC45NTYwMyA0LjY0MDQ0QzguOTg1MDYgNC41NzE1OCA5IDQuNDk3NzcgOSA0LjQyMzIzQzkgNC4zNDg2OSA4Ljk4NTA2IDQuMjc0ODggOC45NTYwMyA0LjIwNjAzQzguOTI3MDEgNC4xMzcxNyA4Ljg4NDQ2IDQuMDc0NjEgOC44MzA4NCA0LjAyMTkzTDQuOTEzMDYgMC4xNjYzNTRDNC44NTk0OSAwLjExMzYxNyA0Ljc5NTg4IDAuMDcxNzgwNyA0LjcyNTg2IDAuMDQzMjM2NUM0LjY1NTg0IDAuMDE0NjkyMyA0LjU4MDc4IDAgNC41MDQ5OSAwQzQuNDI5MTkgMCA0LjM1NDEzIDAuMDE0NjkyMyA0LjI4NDExIDAuMDQzMjM2NUM0LjIxNDA5IDAuMDcxNzgwNyA0LjE1MDQ4IDAuMTEzNjE3IDQuMDk2OTEgMC4xNjYzNTRMMC4xNzkxMzMgNC4wMjE5M0MwLjEyMzM2OCA0LjA3NDEzIDAuMDc4NzkyNiA0LjEzNjc3IDAuMDQ4MDM0NyA0LjIwNjE4QzAuMDE3Mjc1OCA0LjI3NTU4IDAuMDAwOTU3NDg5IDQuMzUwMzQgNC4xMDA4ZS0wNSA0LjQyNjA0Qy0wLjAwMDg3NjQyNyA0LjUwMTc0IDAuMDEzNjI3MSA0LjU3Njg2IDAuMDQyNjk2IDQuNjQ2OTZDMC4wNzE3NjQgNC43MTcwNyAwLjExNDgxIDQuNzgwNzQgMC4xNjkyOTUgNC44MzQyMkMwLjIyMzc4MSA0Ljg4NzcxIDAuMjg4NjAzIDQuOTI5OTMgMC4zNTk5NDEgNC45NTgzOUMwLjQzMTI3OSA0Ljk4Njg1IDAuNTA3NjkgNS4wMDA5OCAwLjU4NDY2OSA0Ljk5OTk1QzAuNjYxNjQ4IDQuOTk4OTEgMC43Mzc2MzcgNC45ODI3MyAwLjgwODE1NyA0Ljk1MjM2QzAuODc4Njc2IDQuOTIxOTkgMC45NDIzMDEgNC44NzgwNCAwLjk5NTI3OCA0LjgyMzExTDQuNTA0OTkgMS4zNjg4M0w4LjAxNDY5IDQuODIzMTFDOC4wNjgxNyA0Ljg3NTk0IDguMTMxNyA0LjkxNzg5IDguMjAxNjcgNC45NDY1NUM4LjI3MTY0IDQuOTc1MjIgOC4zNDY2NyA0Ljk5MDA0IDguNDIyNDcgNC45OTAxOEM4LjQ5ODI2IDQuOTkwMzEgOC41NzMzNSA0Ljk3NTc0IDguNjQzNDIgNC45NDczMkM4LjcxMzQ5IDQuOTE4OSA4Ljc3NzE4IDQuODc3MTcgOC44MzA4NCA0LjgyNDUzWiIgZmlsbD0iIzIyMjIyMiIvPgo8L3N2Zz4K")
        background-position: right 20px bottom 18px;
        + .label {
          z-index: 10000; } }
      &.focus {
        @apply border-blue-500 {}
        @apply outline-none {}
        box-shadow: 0px 0px 15px rgba(39, 79, 237, 0.1);
        + .label {
          z-index: 10000; } } }
    .select-display-placeholder {
      color: #666666; } }
  .icon {
    @apply absolute {}
    top: 16px;
    &-right {
      right: 21px; } }
  &.focused,
  &.active {
    .label {
      @apply rounded-sm {}
      background: #fff;
      font-size: 10px;
      font-weight: 300;
      left: 15px;
      padding: 3px 6px;
      top: -10px; } }
  &.active {
    .label {
      @apply text-gray-500 {} }
    &.gray,
    &.grey {
      .label {
        background: #F0F0F0; } } }
  &.focused {
    .label {
      @apply text-blue-500 {}
      z-index: 10000; } }
  &.disabled {
    .label {
      opacity: .5; } } }
.custom-container {
  @apply mx-auto {}
  @apply w-full {}
  width: 80.55555555555556%;
  @media (max-width: 767px) {
    @apply w-full {}
    @apply px-6 {} } }

.section {
	@apply py-20 {} }


@keyframes fadeInSocial {
  from {
    @apply opacity-0 {} }
  to {
    @apply opacity-100 {} } }
.social-wrapper {
  @apply bg-white flex-col inline-flex justify-center p-2 {}
  animation: fadeInSocial 1s;
  &:before {
    @apply absolute {}
    border: 5px solid transparent;
    border-right-color: #fff;
    content: '';
    right: 100%;
    top: 50%;
    transform: translateY(-50%); } }

footer {
  @apply bg-black {}
  @apply font-normal {}
  @apply text-gray-600 {}
  padding-bottom: 40px;
  padding-top: 110px; }

.footer-nav {
  .nav {
    &-item {
      @apply py-2 {} }
    &-link {
      @apply text-12 {} } } }

.gap-y-10 {
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem; }
