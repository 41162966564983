
#index-sidebar::-webkit-scrollbar {
  width: 4px;
  height: 0;
}

#index-sidebar::-webkit-scrollbar-thumb {
  background-color: rgb(95, 126, 250);
  border-radius: 10px;
}
