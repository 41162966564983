// @font-face {
//   font-family: Axiforma;
//   font-weight: 100;
//   src: url('../fonts/Axiforma/Axiforma Thin.otf');
// }

// @font-face {
//   font-family: Axiforma;
//   font-weight: 200;
//   src: url('../fonts/Axiforma/Axiforma Light.otf');
// }

// @font-face {
//   font-family: Axiforma;
//   font-weight: 300;
//   src: url('../fonts/Axiforma/Axiforma Regular.otf');
// }

// @font-face {
//   font-family: Axiforma;
//   font-weight: 400;
//   src: url('../fonts/Axiforma/Axiforma Book.otf');
// }

// @font-face {
//   font-family: Axiforma;
//   font-weight: 500;
//   src: url('../fonts/Axiforma/Axiforma Medium.otf');
// }

// @font-face {
//   font-family: Axiforma;
//   font-weight: bold;
//   src: url('../fonts/Axiforma/Axiforma Bold.otf');
// }

// @font-face {
//   font-family: Axiforma;
//   font-weight: 700;
//   src: url('../fonts/Axiforma/Axiforma SemiBold.otf');
// }

// @font-face {
//   font-family: Axiforma;
//   font-weight: 800;
//   src: url('../fonts/Axiforma/Axiforma Heavy.otf');
// }

// @font-face {
//   font-family: Axiforma;
//   font-weight: 900;
//   src: url('../fonts/Axiforma/Axiforma Black.otf');
// }


@font-face {
  font-family: 'Product Sans';
  font-weight: 100;
  font-style: normal;
  src: url('../fonts/GoogleProductSans/ProductSans-Thin.ttf') format('ttf');
}

@font-face {
  font-family: 'Product Sans';
  font-weight: 200;
  font-style: normal;
  src: url('../fonts/GoogleProductSans/ProductSans-Thin.ttf') format('ttf');
}

@font-face {
  font-family: 'Product Sans';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/GoogleProductSans/ProductSans-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Product Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/GoogleProductSans/ProductSans-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Product Sans';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/GoogleProductSans/ProductSans-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'Product Sans';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/GoogleProductSans/ProductSans-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'Product Sans';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/GoogleProductSans/ProductSans-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Product Sans';
  font-weight: 800;
  font-style: normal;
  src: url('../fonts/GoogleProductSans/ProductSans-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Product Sans';
  font-weight: 900;
  font-style: normal;
  src: url('../fonts/GoogleProductSans/ProductSans-Black.ttf') format('ttf');
}




@font-face {
  font-family: 'SF UI Text';
  font-weight: normal;
  font-style: normal;
  src: url('../SF-UI-Text/SFUIText-Regular.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-weight: normal;
  font-style: italic;
  src: url('../SF-UI-Text/SFUIText-RegularItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-weight: 200;
  font-style: normal;
  src: url('../SF-UI-Text/SFUIText-Light.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-weight: 200;
  font-style: italic;
  src: url('../SF-UI-Text/SFUIText-LightItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-weight: 400;
  font-style: normal;
  src: url('../SF-UI-Text/SFUIText-Regular.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-weight: 400;
  font-style: italic;
  src: url('../SF-UI-Text/SFUIText-RegularItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-weight: 600;
  font-style: normal;
  src: url('../SF-UI-Text/SFUIText-Semibold.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-weight: 600;
  font-style: italic;
  src: url('../SF-UI-Text/SFUIText-SemiboldItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-weight: 700;
  font-style: normal;
  src: url('../SF-UI-Text/SFUIText-Bold.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-weight: 700;
  font-style: italic;
  src: url('../SF-UI-Text/SFUIText-BoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-weight: 900;
  font-style: normal;
  // src: url('../SF-UI-Text/SFUIText-Heavy.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-weight: 900;
  font-style: italic;
  // src: url('../SF-UI-Text/SFUIText-HeavyItalic.woff') format('woff');
}